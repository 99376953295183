document.querySelector('#info').oninput = (e) => {
    document.querySelector('#chars').innerText = e.target.value.length;
}

document.querySelector('form').onsubmit = (e) => {
    e.preventDefault();

    const data = {};

    document.querySelectorAll('[required]').forEach( x => {
        if ( x.getAttribute('type') === 'checkbox' ) return;
        data[x.getAttribute('name')] = x.value;
    });

    console.log(data);

    fetch('/registration', {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then( res => res.json() ).then( data => {
        if ( data.status == "200" ){
            const form = document.querySelector('form');
            const paragraph = document.createElement('p');
            paragraph.classList.add('finalization');
            paragraph.innerHTML = `<h2>Thank you for your registration!</h2>You can close this window`;
            form.innerHTML = '';
            form.appendChild(paragraph);
        } else {
            window.alert('Some problems occurred during registration process. Please send your request once again');
        }
    });
}